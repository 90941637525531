if (location.host.indexOf("skrill.com") !== -1) {
  // check logged in state
  // add auth script to head
  var skrill_sso_provider = "https://sso.skrill.com/sso";
  //         var skrill_sso_provider = '/fileadmin/templates/json'; // debug
  var script = document.createElement("script");
  script.setAttribute("src", skrill_sso_provider + "/authorized.js?callback=skrill_auth");
  document.head.appendChild(script);
  // callback
  window.skrill_auth = function (authenticated) {
    // sometimes comes back as undefined
    if (authenticated === undefined) {
      // bail out
      return;
    }

    // if user is logged in
    if (authenticated) {
      // add a class to the body
      document.querySelector("html").classList.add("logged-in");
    }
  };
}
